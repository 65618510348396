/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import { graphql } from 'gatsby'
import Post from './post'

const widgetOptions = {
  filters: true,
  mode: 'collapsed-items',
  items: 15,
}

const PostCountry = props => <Post {...props} widgetOptions={widgetOptions} />

export const pageQueryCountry = graphql`
  query CountryPostById($id: String!, $locale: String!) {
    post: prismicPostCountry(id: { eq: $id }, lang: { eq: $locale }) {
      id
      first_publication_date
      last_publication_date
      data {
        full_name
        keywords
        description
        country {
          document {
            uid
            data {
              iso_code
              full_name
              alt_name
              alt_preposition
            }
          }
        }
        body {
          ... on PrismicPostCountryBodyWidget {
            id
            slice_type
          }
          ... on PrismicPostCountryBodyPreface {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPostCountryBodyText {
            slice_type
            id
            primary {
              text {
                html
                raw {
                  text
                  type
                  spans {
                    type
                    data {
                      url
                      target
                      lang
                      link_type
                      slug
                      type
                      id
                      uid
                    }
                    end
                    start
                  }
                }
              }
            }
          }
          ... on PrismicPostCountryBodyLeftText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
        }
      }
    }
    generators: allPrismicGenerator(filter: { uid: { eq: "country" }, lang: { eq: $locale } }) {
      edges {
        node {
          data {
            formula
            formula_description
            formula_h1
            formula_slug
          }
          uid
        }
      }
    }
    allInternalPlans {
      edges {
        node {
          plans {
            alternative_id
            providerId
            name
            capacity
            period
            provider {
              name
              slug
              url
              iconUrl
              directUrl
            }
            planCosts {
              planId
              currency
              cost
            }
          }
        }
      }
    }
    allPostCountry: allPrismicPostCountry(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          data {
            country {
              document {
                uid
              }
            }
          }
        }
      }
    }
  }
`

export default PostCountry
